import React from "react";
import "./FacebookInsta.scss";

export default class FacebookInsta extends React.Component {
  render() {
    return (
      <div className="fb-ig-container">
        <object
          type="text/html"
          className="sm-object"
          target="_top"
          data="https://stackoverflow.com/output=embed"
          aria-label="FB"
        ></object>
        <object
          type="text/html"
          className="sm-object"
          target="_top"
          data="https://stackoverflow.com/"
          aria-label="IG"
        ></object>
      </div>
    );
  }
}
