import React from "react";
import Typical from "react-typical";
import me from "../../assets/images/me.jpg";
import github from "../../assets/images/github.png";
import linkedin from "../../assets/images/linkedin.png";

import "./Welcome.scss";

const Writer = (props) => (
  <Typical
    loop={Infinity}
    wrapper="h2"
    className="welcome-trivia"
    steps={[
      "I hack stuff together",
      1400,
      "I hack stuff together fast",
      1000,
      "Code is like humor. When you have to explain it, it’s bad.",
      1000,
      "Deeply in love with Python and JS",
      1000,
      "The most beautiful thing?",
      500,
      "The most beautiful thing? .",
      200,
      "The most beautiful thing? ..",
      200,
      "The most beautiful thing? ...",
      200,
      "The most beautiful thing? Python.",
      500,
      "The most beautiful thing? Python one-liners.",
      1000,
      "19 Jan 2038 at 3:14:07 AM (End of the word according to Unix–2^32 seconds after January 1, 1970)",
      1000,
      "Dreaming of the day Python will accept $ as variable name",
      1000,
    ]}
  />
);

export default class Welcome extends React.Component {
  render() {
    return (
      <div className="welcome-container">
        <img src={me} alt="" className="welcome-picture" onClick={this.ye} />
        <div className="social-media-container">
          <a
            href="https://www.linkedin.com/in/gabriel-petersson-ai/"
            className="social-media-link"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={linkedin} className="social-media-icon" alt="" />
          </a>
          <a
            href="https://github.com/gabrielpetersson"
            className="social-media-link"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={github} className="social-media-icon" alt="" />
          </a>
        </div>
        <p className="welcome-text">
          Welcome! Nice to meet you. <br className="mobile" /> I'm Gabriel.
        </p>
        <Writer />
      </div>
    );
  }
}
