import React from "react";
import Welcome from "./components/welcome/Welcome";
import Portfolio from "./components/portfolio/Portfolio";
import WhyMe from "./components/me/WhyMe";
import WhoIAm from "./components/me/WhoIAm";
import HireMe from "./components/hireme/HireMe";
import ScrollMeter from "./components/scrollmeter/ScrollMeter";
import MyLatestApp from "./components/latest/MyLatestApp";
import RandomContent from "./components/random/RandomContent";
import ToggleButton from "./components/random/ToggleButton";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Test from "./Test"
import img from "./assets/images/meta-img.png";
import "./App.scss";

export default class App extends React.Component {
  state = {
    randomToggle: true,
  };
  toggleRandom = () =>
    this.setState((prevState) => ({ randomToggle: !prevState.randomToggle }));
  render() {
    return (
      <div className="app">
        <Router>
          <Switch>
            <Route path="/random/fbig">
              <RandomContent />
            </Route>
            <Route exact path="/">
              <Welcome />
              <Portfolio />
              {/* <MyLatestApp /> */}
              <WhyMe />
              <WhoIAm />
              <HireMe />
              <ScrollMeter />
            </Route>
            <Route path="/test">
              <Test />
            </Route>
          </Switch>
          {/* <ToggleButton
            link={this.state.randomToggle ? "/random/fbig" : "/"}
            toggle={this.toggleRandom}
          /> */}
        </Router>
      </div>
    );
  }
}
