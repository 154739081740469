import React from "react";
import { Link } from 'react-router-dom';
import "./ToggleButton.scss";

export default class ToggleButton extends React.Component {
  boxText = () => {
    if (!this.props.active) {
      return (
        <React.Fragment>
          <p>A bunch of random stuff</p>
          <i className="material-icons">arrow_right_alt</i>
        </React.Fragment>
      );
    } else {
      return (
        <React.Fragment>
          <p>Exit</p>
          <i className="material-icons rotate-180">arrow_right_alt</i>
        </React.Fragment>
      );
    }
  };
  render() {
    return (
      <Link to={this.props.link} className="toggle-button" onClick={this.props.toggle}>
        <div className="toggle-button-inner">{this.boxText()}</div>
      </Link>
    );
  }                    
}
                                       