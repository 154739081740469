import React, { Component } from "react";

import "./MyLatestApp.scss";

export default class MyLastestApp extends Component {
  state = {
    active: true,
  };
  toggle = () => {
    this.setState((prevState) => ({ active: !prevState.active }));
    // document.body.style.overflow = this.state.active ? "scroll" : "hidden";
  };
  boxText = () => {
    if (!this.state.active) {
      return (
        <React.Fragment>
          <p>View</p>
          <i className="material-icons rotate-90">arrow_right_alt</i>
        </React.Fragment>
      );
    } else {
      return (
        <React.Fragment>
          <p>Hide</p>
          <i className="material-icons rotate-270">arrow_right_alt</i>
        </React.Fragment>
      );
    }
  };
  render() {
    return (
      <React.Fragment>
        <div className="latest-pop-up-container">
          <div className="latest-headline-container">
            <h2 className="headline white latest-pop-up-headline">
              My current project
            </h2>
            {this.state.active && <p className="latest-pop-up-text">This is a website I'm currently building in React for Possio Tracker AB. Follow vehicle trackers live!</p>}
            <div className="latest-pop-up-button" onClick={this.toggle}>
              <div className="latest-pop-up-button-inner">{this.boxText()}</div>
            </div>
          </div>
        </div>
        <div className="latest-pop-up-wrapper" style={{ height: this.state.active ? "100%" : "0vh", borderBottom:this.state.active ? "50px solid #a2d0e9" : "none"}}>  
          <object
            type="text/html"
            className={"latest-pop-up " + (this.state.active?"active-pop-up":"disabled-pop-up")}
            data="https://possioalarm.com"
            aria-label="Possio Tracker"
          ></object>
        </div>
      </React.Fragment>
    );
  }
}

export class OldMyLastestApp extends Component {
  state = {
    active: false,
  };
  toggle = () => {
    this.setState((prevState) => ({ active: !prevState.active }));
    document.body.style.overflow = this.state.active ? "scroll" : "hidden";
  };
  boxText = () => {
    if (!this.state.active) {
      return (
        <React.Fragment>
          <p>Click here to see my latest project!</p>
          <i className="material-icons">arrow_right_alt</i>
        </React.Fragment>
      );
    } else {
      return (
        <React.Fragment>
          <p>Go back</p>
          <i className="material-icons rotate-180">arrow_right_alt</i>
        </React.Fragment>
      );
    }
  };
  render() {
    return (
      <div className="old-latest-pop-up-container">
        <object
          type="text/html"
          width={this.state.active ? "100%" : "0%"}
          className="old-latest-pop-up"
          height="1000px"
          data="https://possioalarm.com"
          aria-label="Possio Tracker"
        ></object>
        <div className="old-latest-pop-up-button" onClick={this.toggle}>
          <div className="old-latest-pop-up-button-inner">{this.boxText()}</div>
        </div>
      </div>
    );
  }
}
