import React from "react";
import "./HireMe.scss";

export default class HireMe extends React.Component {
  render() {
    return (
      <div
        className="outer-container hire-container"
        onClick={this.updateScroll}
      >
        <div className="inner-container">
          <p className="hire-headline blue">
            Need some coding done? Gabriel loves to help out.
          </p>
          <p className="hire-text blue">
            Call him at any time at 072 548 28 26 or send an email to
            gabriielpetersson@gmail.com!
          </p>
        </div>
      </div>
    );
  }
}
