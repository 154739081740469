import React from "react";
import styled from "styled-components";

const Container = styled.div`
  height: 100%;
  background-color: blueviolet;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Canvas = styled.canvas`
  height: ${(props) => props.height};
  width: ${(props) => props.width};
  transform: ${(props) => "scale(" + props.zoom.toString() + ")"};
  display: none;
  position: absolute;
  z-index: 100;
  left: ${(props) => props.left.toString() + "px"};
  top: 0;
`;

const Videos = styled.div`
  margin-top: 400px;
  position: relative;
  height: ${(props) => props.height};
  width: ${(props) => props.width};
  overflow: hidden;
`;

const Video = ({ videoRef, zoom, left }) => {
  return (
    <video
      autoPlay
      loop
      ref={videoRef}
      style={{
        position: "absolute",
        left: left,
        top: 0,
        transform: "scale(" + zoom.toString() + ")",
      }}
    >
      <source
        src="https://www.w3schools.com/html/mov_bbb.mp4"
        type="video/mp4"
      />
    </video>
  );
};

const Button = styled.div`
  width: 60px;
  height: 25px;
  font-size: 14px;
  display: flex;
  color: black;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  margin-left: auto;
  margin-right: auto;
  background-color: yellow;
`;

const addButtonHandler = (canvasRef, videoElm) => {
  canvasRef.current.style.display = "block";
  const ctx = canvasRef.current.getContext("2d");

  ctx.drawImage(
    videoElm,
    0,
    0,
    canvasRef.current.width,
    canvasRef.current.height
  );
};

const removeButtonHandler = (canvasRef) => {
  canvasRef.current.style.display = "none";
};

export const useRefElementCallback = () => {
  const [dims, setDims] = React.useState({ width: 0, height: 0 });
  const ref = React.useRef(null);

  const setRef = React.useCallback((node) => {
    function onResize({ width, height }) {
      setDims({ width, height });
    }

    const resizeObs = new ResizeObserver((entries) => {
      entries.forEach((entry) => {
        onResize(entry.contentRect);
      });
    });

    if (ref.current) resizeObs.disconnect(); // unobserves previous
    node && resizeObs.observe(node);
    if (node) ref.current = node;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { setRef, element: ref.current, dims };
};

const Test = () => {
  const { setRef, element, dims } = useRefElementCallback();
  const [zoom, setZoom] = React.useState(1);
  const [left, setLeft] = React.useState(1);
  const canvasRef = React.useRef();

  return (
    <Container>
      <Button onClick={() => addButtonHandler(canvasRef, element)}>add</Button>
      <Button onClick={() => removeButtonHandler(canvasRef)}>remove</Button>
      <Button onClick={() => setZoom((prev) => prev + 0.1)}>+</Button>
      <Button onClick={() => setZoom((prev) => prev - 0.1)}>-</Button>
      <Button onClick={() => setLeft((prev) => prev + 40)}>left</Button>
      <Button onClick={() => setLeft((prev) => prev - 40)}>right</Button>
      <Videos
        width={dims.width.toString() + "px"}
        height={dims.height.toString() + "px"}
      >
        <Canvas
          width={dims.width.toString() + "px"}
          height={dims.height.toString() + "px"}
          left={left}
          zoom={zoom}
          ref={canvasRef}
        />
        <Video left={left} zoom={zoom} videoRef={setRef}></Video>
      </Videos>
    </Container>
  );
};

export default Test;
