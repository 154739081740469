import React from "react";
import guitar_video from "../../assets/videos/guitar_video.mp4";
import guitar_image from "../../assets/images/guitar_thumb.jpg";
import github from "../../assets/images/github.png";
import linkedin from "../../assets/images/linkedin.png";

import "./WhoIAm.scss";

export default class WhoIAm extends React.Component {
  br = () => (
    <span className="line-break-height">
      <br />
      <br />
    </span>
  );
  render() {
    return (
      <div className="outer-container">
        <div className="inner-container">
          <h2 className="headline white">WHO AM I</h2>
          <p className="who-description">
            Hi stranger! I'm a data scientist/ML enthuiast/frontend
            developer. I started programming 6 years ago at the age of 13, and
            have not planned to stop anytime soon.
          </p>
          <div className="who-social-media-container">
            <a
              href="https://www.linkedin.com/in/gabriel-petersson-ai/"
              className="social-media-link"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={linkedin} className="who-social-media-icon" alt="" />
            </a>
            <a
              href="https://github.com/gabrielpetersson"
              className="social-media-link"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={github} className="who-social-media-icon" alt="" />
            </a>
          </div>
          <div className="who-projects">
            <p className="who-project-headline">More side projects!</p>
            <div className="who-projects-inner">
              <div className="who-projects-videos-container">
                <div className="guitar-project">
                  <video
                    controls
                    preload="false"
                    className="guitar-video"
                    title="Guitar Video"
                    width="266px"
                    poster={guitar_image}
                  >
                    <source src={guitar_video} type="video/mp4" />
                  </video>
                  <p className="who-project-title"></p>
                </div>
                <div className="guitar-project">
                  <iframe
                    autoPlay={false}
                    className="guitar-video"
                    title="Guitar Video"
                    width="266px"
                    src="https://www.youtube.com/embed/yKozEAf0HL4"
                    frameBorder="0"
                    scrolling="no"
                    poster={guitar_image}
                  />
                  <p className="who-project-title"></p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
