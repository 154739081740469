import React from "react";
import {
    BrowserRouter as Router,
    Switch,
    Route
} from 'react-router-dom'
import FacebookInsta from './projects/FacebookInsta'
import './RandomContent.scss'


const NavTab = ({text}) => (
    <div className="random-content-nav-tab">
        <p>{text}</p>
    </div>
);

export default class BunchOfRandomStuff extends React.Component {
  render() {
    return (
      <div className="random-content-container">
          <nav className="random-content-nav">
              <NavTab text="Instagram Facebook" onclick_to="/fbig"/>
          </nav>
          <div className="random-content-router">
            <Router>
                <Switch>
                    <Route path="/random/fbig">
                        <FacebookInsta />
                    </Route>
                </Switch>
            </Router>

          </div>
      </div>
    );
  }
}
