import React, { Component } from "react";

import "./ScrollMeter.scss";

export default class ScrollMeter extends Component {
  state = {
    scrollPct: 0,
  };
  getScrollPercent = () => {
    var h = document.documentElement,
      b = document.body,
      st = "scrollTop",
      sh = "scrollHeight";
    return ((h[st] || b[st]) / ((h[sh] || b[sh]) - h.clientHeight)) * 100;
  };
  updateScrollY = () => {
    this.setState({ scrollPct: this.getScrollPercent() });
  };
  render() { 
    // cannot pass onscroll event, Typical plugin cannot handle setstates from parents (bug). Doesnt matter since i use transition anyway
    document.onscroll = this.updateScrollY
    return (
      
      <div style={{ width: this.state.scrollPct.toString() + "%" }} className="scroll-meter-outer">
        {/* <div
          className="scroll-meter-inner"
          style={{ width: this.state.scrollPct.toString() + "%" }}
        ></div> */}
      </div>
    );
  }
}
