import React from 'react';
import VizSensor from 'react-visibility-sensor';
import './WhyMe.scss'




class BoxSlider extends React.Component {
    state = {
      imgViz: false
    }
    render() {
        return (
            <VizSensor
                partialVisibility 
                onChange={(isVisible) => {
                    this.setState({imgViz: isVisible})
                }}
                >
                <div className="outer-slider-box">
                    <div  className="inner-slider-box" style={{
                        width: this.state.imgViz ? this.props.pct+"%" : "0%"}}
                    ></div>
                </div>
            </VizSensor>
      );
    }
}


class NamedBoxSlider extends React.Component {
    render() {
        return (
            <div className="named-box-slider-container">
                <p className="named-slider-text">{this.props.text}</p>
                <BoxSlider pct={this.props.pct}/>
            </div>
      );
    }

}


export default class WhyMe extends React.Component {
    state={
        scrollY: 0
    }
    updateScroll=()=>{
        console.log(window.scrollY)
        this.setState({scrollY: window.scrollY})
    }
    render() {
        return (
            <div className="outer-container me-container flex" onClick={this.updateScroll}>
                <p className="headline blue">WHY ME</p>
                <div className="inner-container">
                    <NamedBoxSlider text="Python" pct="104"/>
                    <NamedBoxSlider text="JS" pct="95"/>
                    <NamedBoxSlider text="React" pct="99"/>
                    <NamedBoxSlider text="Machine Learning" pct="95"/>
                    <NamedBoxSlider text="Angular" pct="93"/>
                    <NamedBoxSlider text="Python again" pct="100"/>
                    <NamedBoxSlider text="AWS" pct="90"/>
                </div>
            </div>
        );
    }
}   