import React from "react";
import depict from "../../assets/images/dep.png";
import possio2 from "../../assets/images/possio2.png";
import hittavaran from "../../assets/images/hittavaran.png";
import normative from "../../assets/images/normative.png";
import george from "../../assets/images/georgetown.png";
import "./Portfolio.scss";

// import ai from "../../assets/images/ai.jpg";
// import staples from "../../assets/images/staples.png";
// import possio from "../../assets/images/possio.png";

const ProjectCard = ({
  top,
  badge,
  left,
  title,
  image,
  description,
  right,
  link,
  years,
  small,
}) => (
  <a
    href={link}
    target="_blank"
    without
    rel="noopener noreferrer"
    className={"project-container" + (small ? " small" : "")}
    style={{ top, left, right }}
  >
    <p className="project-badge">{badge}</p>
    {years && <p className="project-badge-years">{years}</p>}
    <div className="project-image-container">
      <img src={image} alt="" className="project-image" />
    </div>
    <p className="project-title">{title} </p>
    <p className="project-description">{description} </p>
  </a>
);

const tc = (color, text) => (
  <span style={{ color, fontWeight: "900" }}>{text}</span>
);

const br = () => (
  <span className="line-break-height">
    <br />
    <br />
  </span>
);

export default class Portfolio extends React.Component {
  render() {
    const orange = "#ffaf7a";

    return (
      <div className="outer-container portfolio-wrapper">
        <p className="headline white">WHAT I DO</p>
        <div className="portfolio-container">
          <ProjectCard
            top={"20px"}
            left={"10%"}
            years={"2 months"}
            badge="Possio Tracker AB"
            image={possio2}
            link="https://possioalarm.com"
            title={"Frontend Consultant"}
            description={
              <span>
                Developed a {tc(orange, "React app")} for Possio Tracker
                {br()}
                {br()}
                {br()}
                {tc(orange, "(click me)")}
              </span>
            }
          />
          {/* <ProjectCard
            top={"200px"}
            link="https://depict.ai"
            left={"60%"}
            years={"6 months"}
            badge="depict.ai"
            image={depict}
            title={"Co-Founder"}
            description={
              <span>
                {tc(orange, "Y Combinator")} participant, summer 2020
                {br()}Product recommendersystem for e-commerce stores
              </span>
            }
          /> */}
          {/* <ProjectCard
            top={"720px"}
            left={"7%"}
            badge="Text generative model"
            image={ai}
            small={true}
            title={"Machine Learning Project"}
            description={
              <span>
                Created an {tc(orange, "AI")} that can talk.
                {br()}Built a recurrent neural network from scratch down to the
                math, that I trained on word2vec embeddings.
              </span>
            }
          /> */}
          <ProjectCard
            top={"800px"}
            left={"55%"}
            years={"2 months"}
            badge="hittavaran.com"
            image={hittavaran}
            link="https://hittavaran.com"
            title={"Co-Founder"}
            description={
              <span>
                Full-stack {tc(orange, "price comparison")} application for
                pharmaceutical products
              </span>
            }
          />
          <ProjectCard
            top={"580px"}
            left={"24%"}
            years={"2 months"}
            badge="Scholarship"
            image={george}
            link="https://hittavaran.com"
            title={"Georgetown University"}
            small={true}
            description={
              <span>
                {br()}Awarded {tc(orange, "8500$")} scholarship for outstanding
                academic results
              </span>
            }
          />
          {/* <ProjectCard
            top={"1260px"}
            left={"2%"}
            small={true}
            badge="Tracking"
            image={staples}
            title={"Frontend Consultant"}
            description={
              <span>
                {tc(orange, "Staples")}, {tc(orange, "Life")},{" "}
                {tc(orange, "KRauta")}, and more
                {br()}Tracking all customer events through their shopping
                journey, later combining them to gain important insights.
              </span>
            }
          /> */}
          <ProjectCard
            top={"1050px"}
            left={"14%"}
            years={"1 year"}
            badge="normative.io"
            image={normative}
            link="https://normative.io"
            title="Data Scientist"
            description={
              <span>
                {tc(orange, "Automating")} sustainability reports
                {br()}Helping companies to calculate their environmental
                footprint
              </span>
            }
          />
        </div>
      </div>
    );
  }
}
